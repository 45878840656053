var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "e-filters",
        {
          attrs: { searching: _vm.fetching },
          on: { search: _vm.filterData, reset: _vm.resetFiltersLocal },
        },
        [
          _c(
            "div",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          name: "name",
                          label: _vm.$t("Nome"),
                          placeholder: _vm.$t("Nome"),
                        },
                        model: {
                          value: _vm.filters.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "name", $$v)
                          },
                          expression: "filters.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "b-card-actions",
        {
          attrs: { "action-refresh": "", "show-loading": _vm.mainCardLoading },
          on: { refresh: _vm.filterData },
        },
        [
          _c(
            "div",
            { attrs: { slot: "title" }, slot: "title" },
            [
              _c("e-page-size-selector", {
                attrs: { "per-page": _vm.paging.pageSize },
                on: { change: _vm.pageSizeChange },
              }),
            ],
            1
          ),
          _c("b-table", {
            ref: "variations-table",
            staticClass: "bordered",
            attrs: {
              "show-empty": "",
              responsive: "",
              striped: "",
              fields: _vm.fields,
              items: _vm.productVariations,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(valueType)",
                fn: function (row) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          row.item.valueType
                            ? _vm.valueTypesText[row.item.valueType]
                            : _vm.$t("Não informado")
                        ) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "cell(active)",
                fn: function (row) {
                  return [
                    _c("e-status-badge", {
                      attrs: { status: row.item.active },
                    }),
                  ]
                },
              },
              {
                key: "cell(isDynamic)",
                fn: function (row) {
                  return [
                    _c("e-boolean-badge", {
                      attrs: { status: row.item.isDynamic },
                    }),
                  ]
                },
              },
              {
                key: "cell(isVisual)",
                fn: function (row) {
                  return [
                    _c("e-boolean-badge", {
                      attrs: { status: row.item.isVisual },
                    }),
                  ]
                },
              },
              {
                key: "cell(action)",
                fn: function (row) {
                  return [
                    _c("e-grid-actions", {
                      attrs: {
                        "is-dropdown": _vm.actionIsDropdown,
                        "is-active": row.item.active,
                        "show-update": _vm.$can("Update", "Variation"),
                        "show-delete": _vm.$can("Delete", "Variation"),
                        "show-activate": _vm.$can("Activate", "Variation"),
                        "show-deactivate": _vm.$can("Deactivate", "Variation"),
                      },
                      on: {
                        update: function ($event) {
                          return _vm.showUpdateVariationSidebar(row)
                        },
                        delete: function ($event) {
                          return _vm.deleteVariation(row)
                        },
                        activate: function ($event) {
                          return _vm.changeVariationStatus(row.item)
                        },
                        deactivate: function ($event) {
                          return _vm.changeVariationStatus(row.item)
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("e-pagination-summary", {
                    attrs: {
                      "current-page": _vm.paging.currentPage,
                      "per-page": _vm.paging.pageSize,
                      total: _vm.paging.rowCount,
                      "total-on-page": _vm.paging.rowsInCurrentPage,
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("b-pagination", {
                    attrs: {
                      align: "right",
                      "total-rows": _vm.paging.rowCount,
                      "per-page": _vm.paging.pageSize,
                      "aria-controls": "variations-table",
                    },
                    on: { change: _vm.pageChange },
                    model: {
                      value: _vm.paging.currentPage,
                      callback: function ($$v) {
                        _vm.$set(_vm.paging, "currentPage", $$v)
                      },
                      expression: "paging.currentPage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("b-sidebar", {
        attrs: {
          id: "sidebar-form-variation",
          "aria-controls": "sidebar-form-variation",
          "aria-expanded": _vm.showSidebar,
          "header-class": "text-center",
          "no-close-on-backdrop": "",
          backdrop: "",
          shadow: "",
          right: "",
          width: "500px",
        },
        on: {
          hidden: function ($event) {
            return _vm.init()
          },
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function (ref) {
              var hide = ref.hide
              return [
                _c("e-sidebar-header", {
                  attrs: {
                    title: _vm.edit
                      ? _vm.$t("Editar Variante")
                      : _vm.$t("Nova Variante"),
                    hide: hide,
                  },
                }),
              ]
            },
          },
          {
            key: "default",
            fn: function () {
              return [
                _vm.fetchingItem
                  ? _c("e-spinner")
                  : _c(
                      "div",
                      { staticClass: "px-2 py-2" },
                      [
                        _c(
                          "FormulateForm",
                          {
                            ref: "VariationForm",
                            attrs: { name: "VariationForm" },
                          },
                          [
                            _c(
                              "div",
                              [
                                _c("FormulateInput", {
                                  staticClass: "required",
                                  attrs: {
                                    id: "variation-name",
                                    "is-edit": false,
                                    type: "text",
                                    label: _vm.$t("Nome"),
                                    validation: "required",
                                  },
                                  model: {
                                    value: _vm.variation.name,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.variation, "name", $$v)
                                    },
                                    expression: "variation.name",
                                  },
                                }),
                                _c("FormulateInput", {
                                  staticClass: "required",
                                  attrs: {
                                    id: "variation-value-type",
                                    type: "vue-select",
                                    label: _vm.$t("Tipo de valor"),
                                    validation: "required",
                                    options: _vm.valueTypes,
                                  },
                                  model: {
                                    value: _vm.variation.valueType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.variation, "valueType", $$v)
                                    },
                                    expression: "variation.valueType",
                                  },
                                }),
                                _c("FormulateInput", {
                                  attrs: {
                                    id: "variation-is-visual",
                                    type: "switch",
                                    label: _vm.$t("Visual"),
                                    instruction: _vm.$t(
                                      "Indica se esta variante possui diferença visual, como Cor, por exemplo."
                                    ),
                                  },
                                  model: {
                                    value: _vm.variation.isVisual,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.variation, "isVisual", $$v)
                                    },
                                    expression: "variation.isVisual",
                                  },
                                }),
                                _vm.variation.valueType !== "Dropdown"
                                  ? _c("FormulateInput", {
                                      attrs: {
                                        id: "variation-is-dynamic",
                                        type: "switch",
                                        label: _vm.$t("Dinâmica"),
                                        instruction: _vm.$t(
                                          "Indica se os valores desta variante são dinâmicos, ou seja, definidos apenas no momento do recebimento do produto."
                                        ),
                                      },
                                      model: {
                                        value: _vm.variation.isDynamic,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.variation,
                                            "isDynamic",
                                            $$v
                                          )
                                        },
                                        expression: "variation.isDynamic",
                                      },
                                    })
                                  : _vm._e(),
                                !_vm.variation.isDynamic &&
                                _vm.variation.valueType === "Dropdown"
                                  ? _c("FormulateInput", {
                                      attrs: {
                                        id: "variation-values",
                                        type: "group",
                                        label: _vm.$t("Valores"),
                                        repeatable: true,
                                        minimum: 1,
                                        "add-label": _vm.$t("Adicionar Valor"),
                                      },
                                      on: {
                                        repeatableRemoved: _vm.valueRemoved,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var index = ref.index
                                              return [
                                                _c(
                                                  "b-row",
                                                  [
                                                    _c(
                                                      "b-col",
                                                      { attrs: { md: "12" } },
                                                      [
                                                        _c("FormulateInput", {
                                                          staticClass:
                                                            "required",
                                                          attrs: {
                                                            id:
                                                              "variation-value-" +
                                                              index,
                                                            type: "text",
                                                            name: "value",
                                                            validation:
                                                              "required",
                                                            label:
                                                              _vm.$t("Valor"),
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        3334797126
                                      ),
                                      model: {
                                        value: _vm.variation.values,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.variation, "values", $$v)
                                        },
                                        expression: "variation.values",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function (ref) {
              var hide = ref.hide
              return [
                _c(
                  "b-row",
                  { staticClass: "px-2 py-2" },
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "mr-1",
                            attrs: { variant: "outline-primary" },
                            on: { click: hide },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("Cancelar")) + " ")]
                        ),
                        _c("e-button", {
                          attrs: {
                            variant: "primary",
                            text: _vm.$t("Salvar"),
                            busy: _vm.saving,
                          },
                          on: { click: _vm.saveVariation },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
        model: {
          value: _vm.showSidebar,
          callback: function ($$v) {
            _vm.showSidebar = $$v
          },
          expression: "showSidebar",
        },
      }),
      _vm.$can("Create", "Variation")
        ? _c("fab", {
            attrs: { "main-tooltip": _vm.$t("Adicionar Linha") },
            on: { click: _vm.showCreateBrandSidebar },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }