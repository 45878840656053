<template>
  <div>
    <e-filters
      :searching="fetching"
      @search="filterData"
      @reset="resetFiltersLocal"
    >
      <div>
        <b-row>
          <b-col md="6">
            <FormulateInput
              v-model="filters.name"
              name="name"
              :label="$t('Nome')"
              :placeholder="$t('Nome')"
            />
          </b-col>
        </b-row>
      </div>
    </e-filters>

    <b-card-actions
      action-refresh
      :show-loading="mainCardLoading"
      @refresh="filterData"
    >
      <div slot="title">
        <e-page-size-selector
          :per-page="paging.pageSize"
          @change="pageSizeChange"
        />
      </div>
      <b-table
        ref="variations-table"
        show-empty
        responsive
        striped
        class="bordered"
        :fields="fields"
        :items="productVariations"
      >
        <template #cell(valueType)="row">
          {{ row.item.valueType ? valueTypesText[row.item.valueType] : $t('Não informado') }}
        </template>
        <template #cell(active)="row">
          <e-status-badge :status="row.item.active" />
        </template>
        <template #cell(isDynamic)="row">
          <e-boolean-badge :status="row.item.isDynamic" />
        </template>
        <template #cell(isVisual)="row">
          <e-boolean-badge :status="row.item.isVisual" />
        </template>

        <template #cell(action)="row">
          <e-grid-actions
            :is-dropdown="actionIsDropdown"
            :is-active="row.item.active"
            :show-update="$can('Update', 'Variation')"
            :show-delete="$can('Delete', 'Variation')"
            :show-activate="$can('Activate', 'Variation')"
            :show-deactivate="$can('Deactivate', 'Variation')"
            @update="showUpdateVariationSidebar(row)"
            @delete="deleteVariation(row)"
            @activate="changeVariationStatus(row.item)"
            @deactivate="changeVariationStatus(row.item)"
          />
        </template>
      </b-table>
      <b-row>
        <b-col cols="6">
          <e-pagination-summary
            :current-page="paging.currentPage"
            :per-page="paging.pageSize"
            :total="paging.rowCount"
            :total-on-page="paging.rowsInCurrentPage"
          />
        </b-col>
        <b-col cols="6">
          <b-pagination
            v-model="paging.currentPage"
            align="right"
            :total-rows="paging.rowCount"
            :per-page="paging.pageSize"
            aria-controls="variations-table"
            @change="pageChange"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <b-sidebar
      id="sidebar-form-variation"
      v-model="showSidebar"
      aria-controls="sidebar-form-variation"
      :aria-expanded="showSidebar"
      header-class="text-center"
      no-close-on-backdrop
      backdrop
      shadow
      right
      width="500px"
      @hidden="init()"
    >
      <template #header="{ hide }">
        <!-- Header -->
        <e-sidebar-header
          :title="edit ? $t('Editar Variante') : $t('Nova Variante')"
          :hide="hide"
        />
      </template>

      <template #default>
        <e-spinner v-if="fetchingItem" />
        <div
          v-else
          class="px-2 py-2"
        >
          <FormulateForm
            ref="VariationForm"
            name="VariationForm"
          >
            <div>
              <FormulateInput
                id="variation-name"
                v-model="variation.name"
                :is-edit="false"
                type="text"
                class="required"
                :label="$t('Nome')"
                validation="required"
              />
              <FormulateInput
                id="variation-value-type"
                v-model="variation.valueType"
                type="vue-select"
                class="required"
                :label="$t('Tipo de valor')"
                validation="required"
                :options="valueTypes"
              />
              <FormulateInput
                id="variation-is-visual"
                v-model="variation.isVisual"
                type="switch"
                :label="$t('Visual')"
                :instruction="
                  $t('Indica se esta variante possui diferença visual, como Cor, por exemplo.')
                "
              />
              <FormulateInput
                v-if="variation.valueType !== 'Dropdown'"
                id="variation-is-dynamic"
                v-model="variation.isDynamic"
                type="switch"
                :label="$t('Dinâmica')"
                :instruction="
                  $t(
                    'Indica se os valores desta variante são dinâmicos, ou seja, definidos apenas no momento do recebimento do produto.'
                  )
                "
              />
              <FormulateInput
                v-if="!variation.isDynamic && variation.valueType === 'Dropdown'"
                id="variation-values"
                v-slot="{ index }"
                v-model="variation.values"
                type="group"
                :label="$t('Valores')"
                :repeatable="true"
                :minimum="1"
                :add-label="$t('Adicionar Valor')"
                @repeatableRemoved="valueRemoved"
              >
                <b-row>
                  <b-col md="12">
                    <FormulateInput
                      :id="`variation-value-${index}`"
                      type="text"
                      name="value"
                      class="required"
                      validation="required"
                      :label="$t('Valor')"
                    />
                  </b-col>
                </b-row>
              </FormulateInput>
            </div>
          </FormulateForm>
        </div>
      </template>

      <template #footer="{ hide }">
        <b-row class="px-2 py-2">
          <b-col cols="12">
            <b-button
              variant="outline-primary"
              class="mr-1"
              @click="hide"
            >
              {{ $t('Cancelar') }}
            </b-button>
            <e-button
              variant="primary"
              :text="$t('Salvar')"
              :busy="saving"
              @click="saveVariation"
            />
          </b-col>
        </b-row>
      </template>
    </b-sidebar>

    <fab
      v-if="$can('Create', 'Variation')"
      :main-tooltip="$t('Adicionar Linha')"
      @click="showCreateBrandSidebar"
    />
  </div>
</template>

<script>
import { BSidebar, BRow, BCol, BButton, BPagination, BTable } from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  ESidebarHeader,
  EButton,
  EPageSizeSelector,
  EPaginationSummary,
  EStatusBadge,
  EBooleanBadge,
} from '@/views/components'
import fab from '@/views/components/FAB.vue'
import { mapActions, mapState } from 'vuex'
import ESpinner from '@/views/components/ESpinner.vue'
import EFilters from '@/views/components/EFilters.vue'
import EGridActions from '@/views/components/EGridActions.vue'

const getInitialVariation = () => ({
  id: null,
  name: '',
  valueType: null,
  active: true,
  isVisual: false,
  isDynamic: false,
  values: [],
})

export default {
  name: 'ProductVariation',
  components: {
    BSidebar,
    BRow,
    BCol,
    BCardActions,
    BButton,
    BPagination,
    BTable,
    ESidebarHeader,
    EButton,
    EPaginationSummary,
    EPageSizeSelector,
    fab,
    EStatusBadge,
    EBooleanBadge,
    ESpinner,
    EFilters,
    EGridActions,
  },

  mixins: [],

  data() {
    return {
      showSidebar: false,
      fetching: false,
      fetchingItem: false,
      updating: false,
      edit: false,
      saving: false,
      variation: getInitialVariation(),
      valuesRemoved: [],
    }
  },

  computed: {
    ...mapState('pages/catalog/productVariations', {
      productVariations: 'productVariations',
      filters: 'filters',
      paging: 'paging',
    }),
    actionIsDropdown() {
      return (
        this.$can('Update', 'Variation') &&
        this.$can('Delete', 'Variation') &&
        (this.$can('Activate', 'Variation') || this.$can('Deactivate', 'Variation'))
      )
    },
    mainCardLoading() {
      return this.fetching || this.updating
    },

    valueTypesText() {
      return {
        Text: this.$t('Texto aberto'),
        Decimal: this.$t('Número decimal'),
        Integer: this.$t('Número inteiro'),
        Dropdown: this.$t('Valor pré-definido'),
      }
    },

    valueTypes() {
      return [
        { value: 'Text', label: this.$t('Texto aberto') },
        { value: 'Decimal', label: this.$t('Número decimal') },
        { value: 'Integer', label: this.$t('Número inteiro') },
        { value: 'Dropdown', label: this.$t('Valor pré-definido') },
      ]
    },

    fields() {
      return [
        {
          label: this.$t('Ações'),
          key: 'action',
          class: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Id'),
          key: 'id',
          class: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Nome'),
          key: 'name',
          class: 'text-left',
        },
        {
          label: this.$t('Tipo de valor'),
          key: 'valueType',
          class: 'text-left',
          thStyle: { width: '150px' },
        },
        {
          label: this.$t('Visual'),
          key: 'isVisual',
          class: 'text-center',
          thStyle: { width: '90px' },
        },
        {
          label: this.$t('Dinâmica'),
          key: 'isDynamic',
          class: 'text-center',
          thStyle: { width: '90px' },
        },
        {
          label: this.$t('Status'),
          key: 'active',
          class: 'text-center',
          thStyle: { width: '90px' },
        },
      ]
    },
  },

  mounted() {
    this.getData()
  },

  methods: {
    ...mapActions('pages/catalog/productVariations', [
      'fetchProductVariations',
      'setCurrentPage',
      'setPageSize',
      'resetFilters',
    ]),

    init() {
      this.variation = getInitialVariation()
      this.valuesRemoved = []
    },

    async getData() {
      try {
        this.fetching = true
        await this.fetchProductVariations()
      } catch (error) {
        this.showGenericError({ error })
      } finally {
        this.fetching = false
      }
    },

    resetFiltersLocal() {
      this.resetFilters()
      this.getData()
    },

    filterData() {
      this.setCurrentPage(1)
      this.getData()
    },

    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.getData()
    },

    pageChange(currentPage) {
      this.$store.dispatch('pages/catalog/productVariations/setCurrentPage', currentPage)
      this.getData()
    },

    showCreateBrandSidebar() {
      this.variation = getInitialVariation()
      this.edit = false
      this.showSidebar = true
    },

    async showUpdateVariationSidebar(row) {
      this.edit = false
      const { id } = row.item
      try {
        this.fetchingItem = true
        this.edit = true
        this.showSidebar = true
        const { data: productVariation } = await this.$http.get(`/api/variations/${id}`)
        this.variation = productVariation
      } catch (error) {
        this.showGenericError({ error })
      } finally {
        this.fetchingItem = false
      }
    },

    async saveVariation() {
      this.$refs.VariationForm.showErrors()
      if (this.$refs.VariationForm.isValid) {
        try {
          this.saving = true

          const body = {
            id: this.variation.id,
            name: this.variation.name,
            active: this.edit ? this.variation.active : true,
            isVisual: !!this.variation.isVisual,
            isDynamic: !!this.variation.isDynamic,
            valueType: this.variation.valueType,
            values: [...this.variation.values, ...this.valuesRemoved],
          }
          await this.$http({
            url: '/api/variations',
            method: this.edit ? 'PUT' : 'POST',
            data: body,
          })
          this.showSuccess({
            message: this.$t(`${this.edit ? 'Atualização' : 'Inclusão'} realizada com sucesso`),
          })
          // this.variation = getInitialVariation()
          this.showSidebar = false
          this.getData()
        } catch (error) {
          this.showError({ error })
        } finally {
          this.saving = false
          this.getData()
        }
      } else {
        this.showInvalidDataMessage()
      }
    },

    async deleteVariation(variation) {
      const { id } = variation.item
      try {
        const confirm = await this.confirm()
        if (confirm) {
          this.updating = true
          await this.$http.delete(`/api/variations/${id}`)
          this.getData()
          this.showSuccess({ message: this.$t('Variante removida com sucesso') })
        }
      } catch (error) {
        this.showError({ error })
      } finally {
        this.updating = false
      }
    },

    valueRemoved(items) {
      const removed = items.find(
        item => this.variation.values.map(variation => variation.id).indexOf(item.id) < 0
      )
      removed.isDeleted = true
      this.valuesRemoved.push(removed)
    },

    async changeVariationStatus(variation) {
      try {
        const confirm = await this.confirm()
        if (confirm) {
          this.updating = true
          await this.$http.put(
            `/api/variations/${variation.active ? 'deactivate' : 'activate'}/${variation.id}`
          )
          this.getData()
          this.showSuccess({
            message: this.$t(`Variante ${variation.active ? 'desativada' : 'ativada'} com sucesso`),
          })
        }
      } catch (error) {
        this.showError({ error })
      } finally {
        this.updating = false
      }
    },
  },
}
</script>

<style></style>
